<template>
  <div class="card shadow">
    <div class="card-top">
      <a @click="pageMove(artistInfo)" class="card-image">
        <img
          :src="'/images/artists/' + encodeURI(artistInfo.artist_image_file_name)"
          :style="imagePreviewPosition"
          decoding="auto"
          loading="lazy"
          fetchpriority="low"
        />
      </a>
      <div class="card-top-right">
        <div class="artist-name-wrap" ref="cardBody">
          <a class="artist-name" ref="cardBodyLink" @click="pageMove(artistInfo)" v-html="$adjustArtistNameForDisplay(artistInfo.artist_name)"></a>
        </div>
        <div class="favorite-button unselectable" :class="{ favorite: artistInfo.isfavorite }" @click="$emit('clickArtistFavorite')">
          <template v-if="artistInfo.isfavorite">フォロー中</template><template v-else>フォローする<i class="icon icon-person_add_alt_1"></i></template>
        </div>
      </div>
    </div>

    <div class="demogra-img-wrapper">
      <div class="y-axis unselectable">
        <p>10代</p>
        <p>20代</p>
        <p>30代</p>
        <p>40代</p>
        <p>50代</p>
        <p style="margin-right: -4px">60代〜</p>
      </div>
      <div class="value unselectable">
        <p v-for="line in generateDecadeData" :key="line">{{ line.toFixed(0) }}%</p>
      </div>
      <img
        :src="`/images/artist_demogra/${artistInfo.artist_code}_${provideDemograSuffix}.webp`"
        class="demogra-img"
        decoding="auto"
        loading="lazy"
        fetchpriority="low"
      />
      <div class="x-axis unselectable">
        <svg height="20" width="16">
          <rect x="0" y="4" width="16" height="16" rx="1" class="女性" />
        </svg>
        <p>女性</p>
        <svg height="20" width="16">
          <rect x="0" y="4" width="16" height="16" rx="1" class="男性" />
        </svg>
        <p>男性</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistCardDemogra',
  inject: ['provideDemograSuffix'],
  props: {
    artistInfo: { type: Object, default: () => ({}) },
  },
  computed: {
    imagePreviewPosition: function () {
      if (parseInt(this.artistInfo.image_position) || 0 !== 0) {
        const style = 'height: unset;position: relative;top: calc(-50% - ' + this.artistInfo.image_position / 2 + 'px)';
        return style;
      } else {
        return '';
      }
    },
    generateDecadeData: function () {
      const decadeOrder = { '10代': 0, '20代': 0, '30代': 0, '40代': 0, '50代': 0, '60代以上': 0 };
      this.artistInfo.fan_age_gender.map((e) => (decadeOrder[e.age] += e.ratio));
      return Object.values(decadeOrder);
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$adjustFontSizeToFit(this.$refs.cardBody, this.$refs.cardBodyLink, 15, 12);
    });
  },
  methods: {
    pageMove(artistInfo) {
      this.$emit('pageMove', artistInfo);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  --card-width: 294px;
  height: min-content;
  width: var(--card-width);
  margin-bottom: 8px;
  background-color: white;
  border-radius: 6px;
  .card-top {
    display: flex;
    gap: 8px;
    .card-image {
      width: 104px;
      height: 69px;
      margin: 8px;
      border: 1px solid var(--gray-02-color);
      border-radius: 3px;
      overflow: hidden;
      img {
        width: 104px;
        height: 69px;
        object-fit: cover;
        vertical-align: middle;
      }
    }
    .card-top-right {
      width: 156px;
      .artist-name-wrap {
        display: grid;
        height: 56px;
        place-content: center;
        text-align: center;
        padding: 0 8px;
        .artist-name {
          font-size: 18px;
          font-weight: bold;
          white-space: nowrap;
          word-break: auto-phrase;
          overflow-wrap: anywhere;
          line-height: 1.2em;
          color: var(--primary-color);
          > ::v-deep(span) {
            display: inline-block;
          }
        }
      }
    }

    .favorite-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      width: 120px;
      margin-inline: auto;
      font-size: 12px;
      font-weight: bold;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      background-color: white;
      border-radius: 13px;
      cursor: pointer;
      &.favorite {
        color: white;
        background-color: var(--primary-color);
      }
      .icon {
        margin-left: 6px;
      }
    }
  }
  .demogra-img-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 6px 11px 4px 17px;
    .y-axis {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 4px 6px 4px;
      font-size: 12px;
      font-weight: bold;
      color: var(--primary-color);
      text-align: right;
      letter-spacing: -0.4px;
    }
    .value {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 60px;
      top: 4px;
      height: 100%;
      padding-bottom: 8px;
      font-size: 12px;
      font-weight: bold;
      color: white;
      filter: drop-shadow(1px 1px 1px var(--primary-color));
    }
    .demogra-img {
      width: 218px;
      height: 154px;
    }
    .x-axis {
      position: absolute;
      display: flex;
      bottom: 4px;
      right: 0;
      margin: 0 5px 4px 0;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.7px;
      svg {
        margin-right: 3px;
        rect.男性 {
          fill: var(--男性);
        }
        rect.女性 {
          fill: var(--女性);
        }
        &:not(:first-child) {
          margin-left: 13px;
        }
      }
    }
  }
}
</style>
